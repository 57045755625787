import React, {Component} from 'react';
import '../App.css';
import './JobList.css';
import {Link} from "react-router-dom";

class JobList extends Component {

    constructor() {
        super();

        this.state = {
            job_list: [
                {
                    job_title: null,
                    location: null,
                    job_id: null
                }
            ]
        };
    }

    setJobList(result) {
        this.setState(
            {
                job_list: result.map(
                    item => (
                        {
                            job_title: item.title,
                            location: item.location,
                            job_id: item.job_id
                        }
                    )
                )
            }
        );
    }

    render() {
        return (
            <div>
                <div className="flex flex-col items-center">
                    <div className="flex-col items-center">
                        <div className="Section-header font-sans">Current Jobs</div>
                    </div>
                    <div className="flex flex-col w-full md:w-3/4 px-4 md:px-0 items-center"> {
                        this.state.job_list
                            .map(
                                (item) => {
                                    return (
                                        <Link
                                            to={`/job/${item.job_id}`}
                                            className={'w-full pr-2'}
                                        >
                                            <div className="flex md:flex-row flex-col
                                            items-center
                                            justify-between
                                            w-full
                                            h-20
                                            m-3
                                            bg-amber-200
                                            shadow-xl
                                            md:rounded-lg
                                            rounded-none
                                            px-5
                                            md:py-2
                                            ">
                                                <div>
                                                    <div className="text-2xl font-sans text-center pt-2 md:pt-0"><span>{item.job_title}</span>
                                                    </div>
                                                </div>
                                                <div className={'flex flex-row md:flex-col pb-2 md:pb-0'}>
                                                    <div className="text-md text-gray-600 px-1"><span>{item.location}</span></div>
                                                    <div className="text-md text-gray-600 px-1"><span>Permanent</span></div>
                                                </div>
                                            </div>
                                        </Link>
                                    );
                                })}
                    </div>
                </div>
            </div>);
    }


    componentDidMount() {
        fetch('https://chyim-career.as.r.appspot.com/api/job/list')
            .then(response => response.json())
            .then(result => {
                return this.setJobList(result)
            })
            .catch(error => error);
    }
}

export default JobList