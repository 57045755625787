import React, {Component} from 'react';
import two_logos from './img/app-logos-small.png';
import './App.css';
import './job/Jobs.css';
import './job_list/JobList.css';
import JobList from "./job_list/JobList";


function Mission() {
    return (
        <div className="flex flex-col items-center pt-10">
            <div className={'Section-header font-sans'}>Our Mission</div>
            <div className="text-center py-10 text-2xl">
                <div>To Make Life Easier for Businesses to Operate Successfully and Sustainably.
                </div>
                <div>Giving our Customers Access to Modern Infrastructure and Technology.</div>
            </div>
        </div>
    )
}

function CoreValueCard(props) {
    return <div className="max-w-md pt-4 px-8 shadow-lg rounded-lg mt-10 w-96">
        <div className="flex justify-center md:justify-end -mt-16 p-5">
        </div>
        <div>
            <h2 className="text-gray-800 text-3xl font-semibold text-center">{props.cardName}</h2>
            <div className="text-lg mt-2 text-gray-600 text-center h-32">
                {props.cardDetails}
            </div>
        </div>
    </div>;
}

function CoreValues() {
    return (
        <div className={'flex flex-col items-center pt-10'}>
            <div className={'Section-header font-sans'}>YIM Core Values</div>
            <div className={'flex flex-col items-center'}>
                <div className={'flex flex-col lg:flex-row items-center pt-10'}>
                    <CoreValueCard cardName={'Make Impact'} cardDetails={
                        <div>
                            <p>We are creating platforms to make our customers successful.</p>
                            <p>We are aiming to make life easier for 100,000+ small businesses</p>
                        </div>}/>
                    <CoreValueCard cardDetails={
                        <div>
                            <p>
                                Be Agile and keep learning. We encourage everyone to take actions, fail, and
                                continuously
                                learn as team.
                            </p>
                        </div>}
                                   cardName={'Evolve Together'}/>
                    <CoreValueCard cardName={'Strive for Results'} cardDetails={
                        <div>
                            <p>Focuses on key inputs to achieve results with quality and in a timely fashion. Be
                                resilient and don't settle!</p>
                        </div>}/>
                </div>
                <div className={'flex flex-row items-center text-2xl w-full'}>
                    <div className="px-8 shadow-lg rounded-lg my-10 w-full h-32 items-center">
                        <h2 className="text-gray-800 text-3xl font-semibold text-center items-center my-5">Most Importantly, Have Fun
                            While Doing Them All!</h2>
                    </div>
                </div>
            </div>
        </div>);
}


function WhatWeDo() {
    return (<div></div>);
}

function NavBar() {
    return (<div>
        <div className="flex flex-row justify-between">
            <div className="flex flex-row items-center">
                <div>
                    <img alt="cho chef logos"
                         className="p-2 object-scale-down h-[5rem]"
                         // style={{height: '100px'}}
                         src={two_logos}
                    />
                </div>
                <div className={'text-3xl px-3 py-3'}>
                    Careers
                </div>
            </div>
            <div className="flex flex-row divide-x-2 items-center">
                <div className={'text-3xl px-3 py-3'}>
                    Team
                </div>
                <div className={'text-3xl px-3 py-3'}>
                    Jobs
                </div>
            </div>
        </div>
    </div>)
}

function InformationSection() {
    return (<div>
        <div className="flex flex-col">
            <Mission/>
            <CoreValues/>
            <WhatWeDo/>
        </div>
    </div>)
}

function Banner() {
    return (<div>
        <div className="flex flex-col">
            <Mission/>
        </div>
    </div>)
}

class App extends Component {

    render() {
        return (
            <div id="App-wrap" className="container p-0 justify-center">
                <NavBar/>
                {/* Shows key messages */}
                {/*<Banner/>*/}
                {/* Shows information of the company */}
                <InformationSection/>
                <JobList/>
                {/*<Example/>*/}
            </div>
        )
    }
}

export default App;
