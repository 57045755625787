import React, {Component} from 'react';
import '../App.css';
import './Jobs.css';
import {useParams} from "react-router-dom";

function Job() {
    const {jobId} = useParams();
    return <JobComponent jobId={jobId}/>;

}

class JobComponent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            currentJob: {
                jobTitle: null,
                description: null,
                url: null,
                department: null,
                location: null,
                objectives: [],
                responsibilities: [],
                qualifications: []
            }
        }

        this.jobId = props.jobId;
        this.setJobResult = this.setJobResult.bind(this);
    }

    setJobResult(result) {
        console.log(result);
        this.setState({
                currentJob: {
                    jobTitle: result.title,
                    description: result.description,
                    url: result.url,
                    department: result.department,
                    location: result.location,
                    job_type: 'Full Time',
                    objectives: result.objectives,
                    responsibilities: result.responsibilities,
                    qualifications: result.qualifications,
                }
            }
        );
    }

    render() {
        console.log(this.state.currentJob.url);

        return (
            <div className="container h-max w-full">
                <div className={'flex flex-col h-full'}>
                    <div className={'flex md:flex-row flex-col justify-between items-center'}>
                        <div className={'flex flex-col h-fit md:p-10 p-3'}>
                            <div className="text-4xl font-bold text-center">{this.state.currentJob.jobTitle}</div>
                            <div className={'flex md:flex-row flex-col py-2'}>
                                <div className="text-xl text-gray-600 px-3">{this.state.currentJob.location}</div>
                                <div className="text-xl text-gray-600 px-3">{this.state.currentJob.job_type}</div>
                                <div className="text-xl text-gray-600 px-3">{this.state.currentJob.department}</div>
                            </div>
                        </div>
                        <div className={'flex flex-col ' +
                            'h-fit py-2 px-3 text-center ' +
                            'w-full align-center ' +
                            'md:py-10 md:px-0 md:w-fit'}>
                            <a href={this.state.currentJob.url}>
                                <div
                                    className="text-xl font-bold w-100
                                    bg-amber-400
                                    px-3
                                    h-12
                                    py-2 rounded-md shadow-lg text-black">Apply
                                    Now
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className={"bg-gray-200 h-max rounded-lg"}>
                        <div className={'flex bg-white m-5 shadow-lg rounded-lg'}>
                            <div className={'flex flex-col'}>
                                <div className="p-3 text-2xl">{this.state.currentJob.description}</div>
                                <div className={'px-3 py-5 text-xl font-semibold'}>Responsibilities</div>
                                <ul className={'list-disc px-10'}>
                                    {
                                        this.state.currentJob.responsibilities.filter(item => item != '').map(item =>
                                            <li>{item}</li>
                                        )
                                    }
                                </ul>
                                <div className={'px-3 py-5 text-xl font-semibold'}>Qualifications</div>
                                <ul className={'list-disc px-10'}>
                                    {
                                        this.state.currentJob.qualifications.filter(item => item != '').map(item =>
                                            <li>{item}</li>
                                        )
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    componentDidMount() {
        const baseURL = 'https://chyim-career.as.r.appspot.com'
        // const baseURL = 'http://127.0.0.1:5000'

        console.log(`${baseURL}/api/job/${this.jobId}`)
        fetch(`${baseURL}/api/job/${this.jobId}`)
            .then(response => response.json())
            .then(result => {
                return this.setJobResult(result)
            })
            .catch(error => error);
    }
}

export default Job;
